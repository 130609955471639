/* eslint-disable no-console */

import { ClientActiveEntities, ClientServiceMessage } from './lib/sw-glue.js';
import { Maybe } from '../common/data.js';

const worker = self as unknown as ServiceWorkerGlobalScope;

let activeEntities: Maybe<ClientActiveEntities> = null;

worker.addEventListener('message', ev => {
	const client = ev.source as WindowClient;
	const msg = ev.data as ClientServiceMessage;
	if (msg.activeEntities && client.focused) {
		activeEntities = msg.activeEntities;
	}
});

worker.addEventListener('fetch', ev => {
	if (ev.request.destination == 'document') {
		if (!activeEntities || !activeEntities.account) return;
		ev.respondWith(fetch(ev.request, {
			headers: {
				'x-active-account': activeEntities.account,
				'x-active-cart': activeEntities.cart || '',
			},
		}));
	}
});

worker.skipWaiting();
worker.clients.claim();
